import React from 'react';
import './news.css'
import abp from './image/abp.png'
import gusec from './image/gusec.png'
import herstart from './image/herstart.png'
import sandesh from './image/google-2.png'
import vishwas from './image/vishwas-removebg-preview.png'

const News = () => {
    return <section class="l-main__section l-main__section--news">
        <h2>News</h2>

        <ul class="l-news">
            <li>
                <a href="./blog.php" class="c-news c-news--cyan">
                    <div class="c-news__title">
                        <div class="product-img">
                            <img src={gusec} width="150" height="70" />
                        </div>
                        <h3>
                            <span>Thrifty AI: A Journey of Empowerment and Innovation with GUSEC</span>
                        </h3>
                    </div>

                    <div class="c-news__meta">
                        <span class="c-news__date">19 NOVEMBER 2022</span>
                    </div>

                </a>

                <div class="l-news__corner"></div>
            </li>
            <li id="first">
                <a href="./blog.php" class="c-news">
                    <div class="c-news__title">
                        <div class="product-img">
                            <img src={herstart} width="150"  />
                        </div>
                        <h3>
                            <span>Empowered by President Murmu's herSTART: Our Experience as the Second Place Winners</span>
                        </h3>
                    </div>
                    <div class="c-news__meta">
                        <span data-aos="slide-in" class="c-news__date">14 OCTOBER 2021</span>
                    </div>
                </a>
                <div class="l-news__corner"></div>
            </li>

            <li id="second">
                <a href="./newsabp.php" class="c-news">
                    <div class="c-news__title">
                        <div class="product-img">
                        <img src={abp} width="100" height="120" />
                        </div>
                        <h3>
                            <span>Thrifty AI was featured in ABP news </span>
                        </h3>
                    </div>
                    <div class="c-news__meta">
                        <span data-aos="slide-in" class="c-news__date">22 July 2021 </span>
                    </div>
                </a>
                <div class="l-news__corner"></div>
            </li>
            <li id="third">
                <a href="./blog.php" class="c-news">
                    <div class="c-news__title">
                        <div class="product-img google">
                            <img
                                src={sandesh}
                                width="100"
                                height="120"
                                id="sandesh"
                            />
                        </div>
                        <h3>
                            <span>
                                Inspiring Growth: The Impact of Google Startup Bootcamp India University Edition on Emerging Startups
                            </span>
                        </h3>
                    </div>
                    <div class="c-news__meta">
                        <span class="c-news__date">21 November 2022</span>
                    </div>
                </a>
                <div class="l-news__corner"></div>
            </li>
            <li id="last">
                <a href="./blog.php" class="c-news c-news--last">
                    <div class="c-news__title">
                        <div class="product-img">
                            <img
                                src={vishwas}
                                width="120"
                                height="180"
                                id="vishwas"
                            />
                        </div>
                        <h3>
                            <span>Artificial Intelligence: Where machines are smarter</span>
                        </h3>
                    </div>
                    <div class="c-news__meta">
                        <span class="c-news__date">3 August 2021</span>
                    </div>
                </a>
                <div class="l-news__corner"></div>
            </li>
        </ul>
    </section>;
};

export default News;
