import React from 'react'
import './footer.css'
import nascom from './img/nascom.png'
import google from './img/google-2.png'
import ssip from './img/ssip.png'
import gusec from './img/Gusec-bw.png'
import meity1 from './img/meity.png'
import meity2 from './img/Meity_2.png'
import ciie  from './img/ciie.png'

const Footer = () => {
    return (

        <div>
        <section class="featured">
            <h2>Supported By </h2>
            <div class="images">
                <img class="img-fluid" src={nascom} width="130px" />
                <img class="img-fluid" src={google} width="100px" />
                <img class="img-fluid" src={meity2} width="130px" />
                <img class="img-fluid" src={gusec} width="120px" />
                <img class="img-fluid" src={meity1} width="120px" />
                <img class="img-fluid" src={ciie} width="120px" />
                <img class="img-fluid" src={ssip} width="120px" />
            </div>
        </section>

        <footer class="footer">
            <div class="footer-waves">
                <video id="footerVideo" preload="metadata" class="highlight__video" autoPlay muted loop playsinline>
                    <source src="https://www.avenga.com/wp-content/themes/custom-theme/dist/videos/mp4-footer-wave-decoration.mp4" type="video/mp4" codecs="avc1.4D401E, mp4a.40.2" />
                    <source src="https://www.avenga.com/wp-content/themes/custom-theme/dist/videos/webm-footer-wave-decoration.webm" type="video/webm" codecs="vp8" />
                </video>
            </div>
            <div class="container">
                <div class="row">
                    <div class="footer-col">
                        <h4>company</h4>
                        <ul>
                            <li><a href="./index.html">Home</a></li>
                            <li><a href="./tech.php">Technology</a></li>
                            <li><a href="./blog.php">Blog</a></li>
                            <li><a href="https://thriftyai.in">CXO</a></li>
                        </ul>
                    </div>

                    <div class="footer-col">
                        <h4>Contact Us</h4>
                        <ul>
                            <li>You can mail us:<a href="mailto: sales@thriftyai.com" id="mail"> sales@thriftyai.com</a></li>
                            <li><a href="./contact.php">Book a Session</a></li>

                        </ul>
                    </div>
                    <div class="footer-col">
                        <h4>follow us</h4>
                        <div class="social-links">
                            <a href="mailto: forthriftyofficial@gmail.com" class="snip1472">
                                <i class="fas fa-envelope-square"></i>
                            </a>
                            <a href='https://www.youtube.com/watch?v=_j3FQf5yqu8'>
                                <i class="fab fa-youtube"></i>
                            </a>
                            <a href="https://www.instagram.com/thrifty.ai/">
                                <i class="fab fa-instagram"></i>
                            </a>
                            <a href="https://www.linkedin.com/company/thrifty-official/">
                                <i class="fab fa-linkedin-in"></i>
                            </a>

                        </div>
                    </div>
                </div>
                <div class="footer-col">
                    <div class="arrow" >
                        <span><a href="  "> </a></span>
                    </div>
                </div>
            </div>
        </footer>
        </div>
    );
}

export default Footer;
